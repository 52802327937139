import toPattern from './toPattern.ts';

export interface MaskOptions {
  dateFormat?: string;
  decimalSeparator?: string;
  fractionGroupSeparator?: string;
  fractionGroupSize?: number;
  groupSeparator?: string;
  groupSize?: number;
  precision?: number;
  prefix?: string;
  secondaryGroupSize?: number;
  suffix?: string;
  timeFormat?: string;
}

export type FormatType = 'custom' | 'currency' | 'date' | 'time';

export type AutoCapitalizeOptions = 'characters' | 'words' | 'sentences' | 'none';

function unMask(value: string, type: 'custom' | 'currency' = 'custom') {
  if (type === 'currency') {
    if (!value) return '0';

    const unMaskedValue = value.replace(/\D/g, '');
    const number = parseInt(unMaskedValue.trimStart());

    return number.toString();
  }

  return value.replace(/\W/g, '');
}

function masker(value: string, pattern: string, options: any) {
  const { autoCapitalize } = options;

  const sentence = toPattern(value, { pattern, ...options });

  switch (autoCapitalize) {
    case 'characters':
      sentence.toUpperCase();
      break;
    case 'words':
      sentence.replace(/(?:^|\s)\S/g, (text) => text.toUpperCase());
      break;
    case 'sentences': {
      // const lower = sentence.toLowerCase();
      // const name = lower.charAt(0).toUpperCase() + lower.substring(1);
      break;
    }
  }

  return sentence;
}

function currencyMasker(value = '0') {
  // const {
  //   decimalSeparator,
  //   fractionGroupSeparator,
  //   fractionGroupSize,
  //   groupSeparator,
  //   groupSize,
  //   precision,
  //   prefix,
  //   secondaryGroupSize,
  //   suffix,
  // } = options;
  //
  // const precisionDivider = parseInt(1 + '0'.repeat(precision || 0));
  // const number = parseInt(value) / precisionDivider;
  //
  // const formatter = {
  //   decimalSeparator,
  //   fractionGroupSeparator,
  //   fractionGroupSize,
  //   groupSeparator,
  //   groupSize: groupSize || 3,
  //   prefix,
  //   secondaryGroupSize,
  //   suffix,
  // };

  // const BigNumber = (await import('bignumber.js')).default;

  // const bigNumber = new BigNumber(number);

  // BigNumber.config({ FORMAT: formatter });

  return value;
}

function dateMasker(value = '', options: any) {
  const { dateFormat = 'yyyy/mm/dd' } = options;

  const regex = /[a-zA-Z]/gi;
  const pattern = dateFormat.replaceAll(regex, '9');
  return masker(value, pattern, {});
}

function timeMasker(value = '', options: any) {
  const { timeFormat = 'HH:mm:ss' } = options;

  return masker(value, timeFormat, {});
}

function multiMasker(value: string, patterns: string[], options: any) {
  return masker(
    value,
    patterns.reduce(
      (memo: string, pattern: string) => (value.length <= unMask(memo).length ? memo : pattern),
      patterns[0],
    ),
    options,
  );
}

function mask(
  value: string | number,
  pattern: string | string[] = '',
  type: FormatType = 'custom',
  options?: any,
  autoCapitalize?: AutoCapitalizeOptions,
): string {
  if (type === 'currency') {
    return currencyMasker(String(value));
  }

  if (type === 'date') {
    return dateMasker(String(value), options);
  }

  if (type === 'time') {
    return timeMasker(String(value), options);
  }

  if (typeof pattern === 'string') {
    return masker(String(value), pattern || '', {
      autoCapitalize,
    });
  }

  return multiMasker(String(value), pattern, {});
}

export { mask, unMask };
